import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import { DepartmentDetail } from "../../apis/dto/DepartmentDetail";
import { fetchDepartmentByDeptCode } from "../../features/departments";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import LoadingWheel from "../loadingWheel/LoadingWheel";
import { setShowDepartmentList } from "../../features/peopleSearch";
import { toastr } from "react-redux-toastr";

type Props = PropsFromRedux & {};

const DepartmentHeader = (props: Props) => {
  const { deptCode } = useParams();
  const {
    departmentDetails,
    error,
    loading,
    fetchDepartmentByDeptCode,
    setShowDepartmentList,
  } = props;

  //const deptSelected: DepartmentDetail = {} as DepartmentDetail;
  // Second Argument Not provided: Runs after EVERY rendering
  // An empty array []: the side-effect runs once after the initial rendering
  // Has props or state values [prop1, prop2, ..., state1, state2]: the side-effect runs only when any depenendecy value changes.
  useEffect(() => {
    if (error) {
      toastr.error(error.title, error.message);
    }
    // Side-effect...
    if (
      deptCode &&
      !(deptSelected && Object.keys(deptSelected).length > 0) &&
      !error
    ) {
      fetchDepartmentByDeptCode(deptCode);
    }

    return function cleanup() {
      // Side-effect cleanup...
    };
  }, [props.departmentDetails, error]);

  const deptSelected: DepartmentDetail =
    departmentDetails.find((dept) => dept.deptCode === deptCode) ??
    ({} as DepartmentDetail);

  return (
    <React.Fragment>
      <LoadingWheel
        numberOfDivs={4}
        showLoading={loading === "pending"}
        spinnerOnly={false}
      />
      <Link
        to="/"
        onClick={() => {
          setShowDepartmentList(true);
        }}
      >
        ← All Departments
      </Link>
      <div className="Department-block">
        <div className="Department-block-overview">
          <div className="Department-block-overview-header">
            <h1 className="Department-block-overview-header-title">
              {deptSelected.name}
            </h1>
            {deptSelected.currentUserIsAdmin && (
              <Link
                className="EditButton"
                to={`/departmentPreferences/${deptCode}`}
              >
                Edit Info
              </Link>
            )}
          </div>
          {deptSelected.description && (
            <div className="Department-block-overview-description">
              {deptSelected.description}
            </div>
          )}
        </div>
        <div className="Department-block-contactInfo">
          {deptSelected.departmentEmail && (
            <div className="TableRow">
              <div className="TableRow-label">email</div>
              <div className="TableRow-value">
                {deptSelected.departmentEmail && (
                  <a href={`mailto:${deptSelected.departmentEmail}`}>
                    {deptSelected.departmentEmail}
                  </a>
                )}
              </div>
            </div>
          )}
          {deptSelected.phoneNumber && (
            <div className="TableRow">
              <div className="TableRow-label">phone</div>
              <div className="TableRow-value">
                {deptSelected.phoneNumber && (
                  <a href={`tel:${deptSelected.phoneNumber}`}>
                    {deptSelected.phoneNumber}
                  </a>
                )}
              </div>
            </div>
          )}
          {deptSelected.faxNumber && (
            <div className="TableRow">
              <div className="TableRow-label">fax</div>
              <div className="TableRow-value">
                {deptSelected.faxNumber && (
                  <a href={`tel:${deptSelected.faxNumber}`}>
                    {deptSelected.faxNumber}
                  </a>
                )}
              </div>
            </div>
          )}
          {deptSelected.url && (
            <div className="TableRow">
              <div className="TableRow-label">website</div>
              <div className="TableRow-value">
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`${deptSelected.url}`}
                >
                  {deptSelected.url}
                </a>
              </div>
            </div>
          )}
          {deptSelected.location && (
            <div className="TableRow">
              <div className="TableRow-label">location</div>
              <div>{deptSelected.location}</div>
            </div>
          )}
          {deptSelected.address && (
            <div className="TableRow">
              <div className="TableRow-label">address</div>
              <div>{deptSelected.address}</div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const mapStateToProps = (state: RootState) => {
  const { entities, error, loading } = state.departments.departmentDetail;
  return {
    departmentDetails: entities,
    error,
    loading,
  };
};

const mapDispatch = {
  fetchDepartmentByDeptCode,
  setShowDepartmentList,
};

const connector = connect(mapStateToProps, mapDispatch);

export default connector(DepartmentHeader);
